import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo"

const IndexPage = () => {

//   const {allMdx} = useStaticQuery(graphql`query allBlogs {
//   allMdx(sort: {fields: [frontmatter___date], order: DESC}) {
//     nodes {
//       id
//       slug
//       excerpt(pruneLength: 250)
//       frontmatter {
//         title
//         date(formatString: "YYYY MMMM Do")
//       }
//     }
//   }
// }
// `)
  return <>
      {/* Main home landing container */}
      {/* Write an intro that sounds quirky and joyous. Mention the fact that I am a software developer and that I am based out of Calgary */}
      {/* Write a brief description of what I do */}
      <SEO title="Munib Rahman" description={"Hello, my name is Munib, and I'm a software engineer."} />
      <div className=" max-w-prose mx-auto p-5">
        <div className="py-10">
          <StaticImage
            className="py-10 rounded-full"
            alt="me"
            src="../images/landing.webp"
            layout="fixed"
            width={350}
            height={350}
            />
        </div>
        <div className="">
            <p className="py-3">
              Hello, my name is Munib 👋 I'm a software engineer!
            </p>

            <p className="pt-5">
              Here are some of things I've worked on in the past:
            </p>

            <ul className="p-5">
              {['Augmented Reality', 'Mobile Applications', 'Distributed Systems', 'Cloud Infrastructures', 'Machine Learning'].map(e=> <li className="list-disc" key={e}>{e}</li>)}
            </ul>

            <p className="py-5">Need to get in touch? murahman at ucalgary dot ca</p>

            <p className="py-5">Socials:</p>
            <div>
              <a className="link hover:underline text-blue-600" href="https://twitter.com/munibca">Twitter</a>
            </div>
            <div>
              <a className="link hover:underline text-blue-600" href="https://www.github.com/munibrahman">Github</a>
            </div>
            <div>
              <a className="link hover:underline text-blue-600" href="https://www.instagram.com/fathermunib">Instagram</a>
            </div>
        </div>

        {/* <div className="justify-center">
            <div className="flex flex-col mx-auto">
                {allMdx.nodes.map(({ id, excerpt, frontmatter, slug }) => {
                    return (
                        <Link to={`/${slug}`} className= "m-5 bg-red-200">
                            <h1>{frontmatter.title}</h1>
                            <h2>{excerpt}</h2>
                        </Link>
                        )
                })}
            </div>
        </div>   */}
      </div>
      
  </>;
  }

export default IndexPage